.payment {
  display: flex;
  justify-content: center;
  align-items: center;
  /* align-content: center; */
  /* display: flex; */

  height: 100%;
  min-height: 450px;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  color: #6b7c93;
  background-color: #f7f8f9;
  overflow: scroll;
}

.payment .stripe-payment-sepa * {
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.payment form {
  max-width: 496px !important;
  position: relative;

  padding: 25px;
}

.payment .split-form {
  display: flex;
  justify-content: space-between;
}

.payment label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.payment input,
.payment .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  min-width: 200px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.payment .__PrivateStripeElement {
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  display: block !important;
  background: transparent !important;
  position: relative !important;
  opacity: 1 !important;
}

.payment .error {
  color: #c23d4b;
  margin: 5px 0;
}

.payment .stripe-payment-sepa button {
  display: block;
  width: 100%;
  height: 37px;
  background-color: #6772e5;
  border-radius: 2px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.payment #mandate-acceptance {
  margin-top: 8px;
  font-size: 14px;
  text-align: justify;
}
